import { EuiButton } from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_dark.css';
import { useEffect, useState } from 'react';
import LandingPage from './components/LandingPage';
import { encode } from 'base64-arraybuffer';
import axios from 'axios';
import { decode } from 'base64-arraybuffer';

const backend_endpoint = "https://backend.auth.cybersift.io"

const getPublicKeyCredentialRequestOptions = (credentialId, challenge) => ({
  challenge: Uint8Array.from(challenge, c => c.charCodeAt(0)).buffer,
  allowCredentials: [{
      id: credentialId,//Uint8Array.from(credentialId, c => c.charCodeAt(0)).buffer,
      type: 'public-key',
      transports: ['internal'],
  }],
  userVerification: "discouraged",
  timeout: 60000,
})

function hexEncode(buf) {
  return Array.from(buf)
              .map(function(x) {
                  return ("0" + x.toString(16)).substr(-2);
      })
              .join("");
}

function b64enc(buf) {
  return encode(buf)
                 .replace(/\+/g, "-")
                 .replace(/\//g, "_")
                 .replace(/=/g, "");
}

function b64RawEnc(buf) {
  return encode(buf)
  .replace(/\+/g, "-")
  .replace(/\//g, "_");
}

function getCredID(){
  if (window.location.hash===""){
    return null
  }
  if (window.location.hash==="#") {
    return null
  } 
  
  let strCredId = window.location.hash.replace("#","").split(":::")[0]
  strCredId=strCredId.replace(/-/g, "+").replace(/_/g, "/");

  return decode(strCredId)
}

function getChallenge(){
  if (window.location.hash===""){
    return null
  }
  if (window.location.hash==="#") {
    return null
  } 
  
  let challenge = window.location.hash.split(":::")[1]

  return challenge
}

function App() {

  const [publicKey, changePublicKey] = useState()
  const [userId, changeUserId] = useState(window.localStorage.getItem("userId"))
  const [credId, changeCredId] = useState(getCredID())
  const [challenge, changeChallenge] = useState(getChallenge())

  window.onhashchange = e => {
    changeCredId(getCredID())
    changeChallenge(getChallenge())
  }

  useEffect(()=>{

    if (!userId){
      return
    }

    window.localStorage.setItem("userId", userId)
    
  },[userId])


  useEffect(()=>{

    if (!publicKey){
      return
    }

    changeCredId(publicKey.rawId)    

    axios.post(`${backend_endpoint}/verify_credential_info`, {      
      userId: userId,
      id: publicKey.id,
      rawId: b64enc(publicKey.rawId),
      type: publicKey.type,
      clientData: b64RawEnc(publicKey.response.clientDataJSON),
      attObj: b64RawEnc(new Uint8Array(publicKey.response.attestationObject)),
    }).then(resp=>{
      alert("User Registered")
      console.log(resp.data)
    })
    
  },[publicKey, userId])

  return (
    <div className="App">
      <LandingPage 
        title={"Demo"}
        content={<>               
        {credId && challenge ? <EuiButton style={{margin: 5}} color="primary" fill onClick={()=>{
                      navigator.credentials.get({
                        publicKey: getPublicKeyCredentialRequestOptions(credId, challenge)
                    }).then(assertion => {
                      const authData = new Uint8Array(assertion.response.authenticatorData);
                      const clientDataJSON = new Uint8Array(assertion.response.clientDataJSON);
                      const rawId = new Uint8Array(assertion.rawId);
                      const sig = new Uint8Array(assertion.response.signature);
                      const assertionClientExtensions = assertion.getClientExtensionResults();
                      
                      axios.post(backend_endpoint+"/verify_attestation_info", {
                        userId: userId,
                        id: assertion.id,
                        rawId: b64enc(rawId),
                        type: assertion.type,
                        authData: b64RawEnc(authData),
                        clientData: b64RawEnc(clientDataJSON),
                        signature: hexEncode(sig),
                        assertionClientExtensions: JSON.stringify(assertionClientExtensions)
                      }).then(resp=>{
                        
                        alert("User Authenticated Successully")
                        window.parent.postMessage("successAuth","*"); 

                      })
                    }).catch(err=>{
                      alert(err)
                    });
                    }}>
                      Authenticate!
                  </EuiButton>     
        : <EuiButton style={{margin: 5}} color="primary" fill onClick={()=>{
                  const username = window.prompt("Please enter your desired username:")
                  const customer = window.prompt("Please enter your customer ID:")

                  axios.post(backend_endpoint+"/credential_options", {
                    username: username,
                    customerID: customer
                  }).then(resp=>{
                    changeUserId(resp.data.user_id)
                    const publicKeyCredentialCreationOptions = {
                      challenge: Uint8Array.from(resp.data.challenge, c => c.charCodeAt(0)).buffer,
                      rp: {
                          name: resp.data.rp_name,
                          id: "auth.cybersift.io",
                      },
                      user: {
                          id: Uint8Array.from(resp.data.user_id, c => c.charCodeAt(0)),
                          name: resp.data.username,
                          displayName: resp.data.display_name,
                      },
                      pubKeyCredParams: [{alg: -7, type: "public-key"}],
                      authenticatorSelection: {
                          userVerification: "discouraged",
                          authenticatorAttachment: "platform",
                      },
                      timeout: 60000,
                      attestation: "none"
                    };

                    navigator.credentials.create({
                      publicKey: publicKeyCredentialCreationOptions
                    }).then(credential => {
                      changePublicKey(credential)
                    }).catch(err=>alert(err));
                  })
                  
                }}>
                  Register New Credential
            </EuiButton>}             
        </>
      }></LandingPage>
    </div>
  );
}

export default App;
