import { EuiPageTemplate, EuiEmptyPrompt } from '@elastic/eui';
import React from 'react';

const LandingPage = ({ button = <></>, content, sideNav, title }) => (
  <EuiPageTemplate
    template="centeredContent"
    pageContentProps={{ paddingSize: 'none' }}
    pageSideBar={sideNav}
    pageHeader={{
      iconType: 'users',
      pageTitle: 'CyberSift Auth',
      rightSideItems: [button],
    }}
  >
    <EuiEmptyPrompt title={<span>{title}</span>} body={content} />
  </EuiPageTemplate>
);

export default LandingPage